
  import { defineComponent } from 'vue';
  import { ElDrawer } from 'element-plus';

  export default defineComponent({
    props: {
      team: String,
      size: Number
    },
    data() {
      return {
        teamItems: this.team ? JSON.parse(this.team) : [],
        wrapperEl: null,
        containerEl: null,
        drawerEl: null,
        visible: false,
        content: null
      }
    },
    components: {
      ElDrawer
    },
    mounted() {
      this.wrapperEl = this.$el;
    },
    methods: {
      close() {
        this.visible = false;
        this.content = null;
      },

      open(content : any) {
        this.visible = true;
        this.content = content;
      }
    }
  })

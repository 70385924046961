
  import { defineComponent } from 'vue';
  import { ElTabs } from 'element-plus';

  export default defineComponent({
    data() {
      return {
        activeName: '1',
      }
    },
    components: {
      ElTabs
    }
  })


  import { defineComponent, ref } from 'vue';
  import { ElDialog} from 'element-plus';

  export default defineComponent({
    components: {
      ElDialog
    },
    props: {
      services: String,
      cssClass: String,
      closeBtnText: String,
    },
    data() {
      return {
        modalVisible: false,
        servicesItems: this.services ? JSON.parse(this.services) : [],
        activeServiceId: 0,
        initChange: false,
        initExecuted: false,
      }
    },
    setup() {
      const elDialog = ref<HTMLFormElement | null>(null);
      return {elDialog};
    },
    watch: {
      modalVisible: {
        handler() {
          this.focusToInnerDialog()
        }
      }
    },
    methods: {
      openModal(id: any) {
        this.modalVisible = true
        if (id) {
          if (!this.initExecuted) {
            this.initChange = true
            setTimeout(() => {
              this.initChange = false,
              this.initExecuted = true
              setTimeout(() => {
                this.activeServiceId = id
              }, 600)
            }, 300)
          } else {
            this.activeServiceId = id
          }
        }
      },

      close() {
        this.modalVisible = false
        this.initExecuted = false
        this.activeServiceId = 0
      },
      focusToInnerDialog() {
        setTimeout(() => {
          const activeItem = this.elDialog?.querySelector('.active') as HTMLElement | null;

          activeItem ? activeItem.focus() : ''
        }, 300)
      }
    }
  })


  import { defineComponent } from 'vue';
  import { Swiper, SwiperSlide} from "swiper/vue";
  import SwiperCore, { Pagination } from 'swiper';
  import "swiper/css";

  SwiperCore.use([Pagination]);

  export default defineComponent({
    props: {
      swiperOptions: {
        type: Object,
        default: {
          autoHeight: true,
          threshold: 10,
          watchOverflow: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        }
      }
    },
    components: {
      swiper: Swiper,
    },
  })

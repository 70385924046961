
  import { defineComponent } from 'vue';
  import { Swiper, SwiperSlide} from "swiper/vue";
  import SwiperCore, { Navigation, Pagination } from 'swiper';
  import "swiper/css";

  SwiperCore.use([Navigation, Pagination]);

  export default defineComponent({
    props: {
      swiperOptions: {
        type: Object,
        default: {
          autoHeight: true,
          calculateHeight: true,
          spaceBetween: 200,
          threshold: 10,
          watchOverflow: true,
          navigation: {
            prevEl: '.swiper-button--prev',
            nextEl: '.swiper-button--next',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        }
      }
    },
    components: {
      swiper: Swiper,
    },
  })

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, {
    modelValue: _ctx.activeName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["modelValue"]))
}
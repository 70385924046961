import '../Scss/style.scss';
import { defineAsyncComponent } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Component } from '@vue/runtime-core';
import createApp, { VueDsCookies } from '@digitalwerk/frontend-muster';
import ReviewSwiper from './Components/ReviewSwiper.vue';
import SelectedCasesSwiper from './Components/SelectedCasesSwiper.vue';
import Service from './Components/Service.vue';
import Contacts from './Components/Contacts.vue';
import Team from './Components/Team.vue';
import { ElTabPane } from 'element-plus';

// // import * as Sentry from "@sentry/vue";
//
// //TODO: Vue Sentry, DNS must be https
// // Sentry.init({
// //     Vue: Vue,
// //     dsn: "http://4d7eadae930d4b6eae70a46050ff6f4e@195.2.221.3:9000/5",
// // });
// // Vue.config.errorHandler = function (err, vm, info) {
// //     Sentry.captureMessage(err.toString())
// // }
// // Vue.config.warnHandler = function (msg, vm, trace) {
// //     Sentry.captureMessage(msg.toString())
// // }

const projectRootComponent:Component = {
  components: {
    ElTabPane,
    'review-swiper': ReviewSwiper,
    'selected-cases-swiper': SelectedCasesSwiper,
    'service': Service,
    'contacts': Contacts,
    'team': Team,
  },
  methods: {
    mountedHook() {
      this.toggleBodyClassIfFooterIsInViewport()
      window.addEventListener('scroll', this.toggleBodyClassIfFooterIsInViewport)

      this.initScrollActive()
      this.checkIfContactExist()
    },

    openContactDrawer(content : any) {
      this.$refs.contactDrawer.open(content);
    },

    isInViewport ( elem : any ) {
      let bounding = elem.getBoundingClientRect();
      let heightOfElement = elem.clientHeight;
      let clientHeight = document.documentElement.clientHeight;

      return ( ((bounding.top - clientHeight) * -1) >= 0 );
    },

    toggleBodyClassIfFooterIsInViewport() {
      const footer = document.querySelector('.footer');
      if (this.isInViewport(footer)) {
        document.body.classList.add('is-footer-visible')
      } else {
        document.body.classList.remove('is-footer-visible')
      }
    },

    closeAllPopups() {
      this.$refs.contactDrawer.close();
      this.$refs.serviceDrawer.close();
    },

    closeMobileNavi() {
      this.$refs.pageHeader.toggleMobileNav();
    },

    initScrollActive() {
      const sections = document.querySelectorAll("section.use-in-navi");
      const navAnchors = document.querySelectorAll(".main-nav-link");

      window.onscroll = () => {
        let current = "";

        sections.forEach((section : any) => {
          const sectionTop = section.offsetTop;
          const sectionTag = section.querySelector('.section-tag') as HTMLElement;

          if (window.pageYOffset >= sectionTop - 105) {
            current = sectionTag.getAttribute("id")!;
          }
        });

        navAnchors.forEach((link) => {
          link.classList.remove("scroll-active");
          if (link.classList.contains(current)) {
            link.classList.add("scroll-active");
          }
        });
      }
    },

    checkIfContactExist() {
      const contactElement = document.getElementById('contact')
      const getInTouch = document.querySelector('.btn-get-in-touch')

      contactElement && getInTouch ? getInTouch.classList.remove('d-none') : ''
    }
  }
};

// @ts-ignore
const app = createApp(projectRootComponent);

app.use(VueDsCookies, {
  categories: ['functional', 'analytical', 'socialmedia']
});

// app.use(DsPhotoSwipe);

app.mount('#page');
